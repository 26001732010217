import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    NavLink
} from 'react-router-dom';
import { InboxInIcon, MailIcon, SearchIcon } from '@heroicons/react/solid'
import NavLogin from './components/NavLogin';
import ParcelsToShip from './components/ParcelsToShip';
import SearchParcel from './components/SearchParcel';
import OrdersList from './components/OrdersList.js';
import Login from './components/Login.js';
import { checkLoggedIn, getDocumentsCount, getUser } from './services/functions';

function Nav() {
  const { t } = useTranslation();
  var [userFirstName, setUserFirstName] = useState('(firstName)');
  var [userLastName, setUserLastName] = useState('(lastName)');
  var [userCurrentCompany, setUserCurrentCompany] = useState('(company)');
  var [ordersNotCompletedCount, setOrdersNotCompletedCount] = useState(0);
  var [ordersPartiallyCompletedCount, setOrdersPartiallyCompletedCount] = useState(0);
  var [parcelsToShipCount, setParcelsToShipCount] = useState(0);

  document.title = t('app.title');

  getUser().then(function(res) {
    if (res) {
      setUserFirstName(res.firstName);
      setUserLastName(res.lastName);
      setUserCurrentCompany(res.currentCompany);
    }
  });
  
  const [logged, setLogged] = useState(() => {
    checkLoggedIn().then(function(res) {
      setLogged(res.logged);
      setUserCurrentCompany(res.company);
      updateOrdersCount();
    });
  });

  useEffect(() => {
    // checkLoggedIn().then(function(res) {
    //   setLogged(res.logged);
    //   console.log('in App useEffect function');
    // });
  });

  function updateOrdersCount() {
    getDocumentsCount().then(function(res) {
      if (res && res.ordersNotPrepared) {
        setOrdersNotCompletedCount(res.ordersNotPrepared);
      } else {
        setOrdersNotCompletedCount(0);
      }

      if (res && res.ordersPartiallyPrepared) {
        setOrdersPartiallyCompletedCount(res.ordersPartiallyPrepared);
      } else {
        setOrdersPartiallyCompletedCount(0);
      }

      if (res && res.parcelsToShip) {
        setParcelsToShipCount(res.parcelsToShip);
      } else {
        setParcelsToShipCount(0);
      }
    });
  }

  const handleCheckIfUserIsLogged = useCallback((res) => {
    if (res !== undefined && res.logged !== undefined) {
      setLogged(res.logged);
    }
  }, []);

  const handleCallBack = useCallback((res) => {
    if (res !== undefined && res !== null) {
      setOrdersNotCompletedCount(res.ordersNotPrepared);
      setOrdersPartiallyCompletedCount(res.ordersPartiallyPrepared);
      setParcelsToShipCount(res.parcelsToShip);
    }
  }, []);

  return (
      <Router>
          <div className="navRouter">
            <nav className="nav">
                {logged ?
                    <div>
                      <span>
                          <h2>{t('common.welcome')} {userFirstName + ' ' + userLastName} !</h2>
                          <h2 className="connectedCompany">{t('login.connectedTo') + ' ' + userCurrentCompany}</h2>
                          <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/orders-to-complete">
                              <InboxInIcon className="navicon" />
                              {t('orders.toComplete')}
                              ({ordersNotCompletedCount})
                          </NavLink>
                          <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/orders-partially-completed">
                              <InboxInIcon className="navicon" />
                              {t('orders.partiallyCompleted')}
                              ({ordersPartiallyCompletedCount})
                          </NavLink>
                          <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/parcels-to-ship">
                              <MailIcon className="navicon" />
                              {t('orders.toShip')}
                              ({parcelsToShipCount})
                          </NavLink>
                          <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/search-parcel">
                              <SearchIcon className="navicon" />
                              {t('common.searchParcel')}
                          </NavLink>
                          {/* <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/orders-completed">
                              <CheckIcon className="navicon" />
                              {t('orders.completed')}
                          </NavLink> */}
                          {/* <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/users">
                              <UserIcon className="navicon" />
                              {t('users.users')}
                          </NavLink> */}
                          {/* <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/settings">
                              <AdjustmentsIcon className="navicon" />
                              {t('settings.settings')}
                          </NavLink> */}
                      </span>
                    </div>
                    : ''
                }
                <NavLogin logged={logged} />
                <p className="appName">{t('app.title')} {t('app.subtitle')} - {t('app.year')} - v.{t('app.version')}</p>
            </nav>

            <div className="nav-content">
                <Routes>
                    <Route path="/orders-to-complete" element={<OrdersList translation={t} preparedStatus="NO" pagetitle={t('orders.toComplete')} parentCallback={handleCallBack} />} />
                    <Route path="/orders-partially-completed" element={<OrdersList translation={t} preparedStatus="PARTIAL" pagetitle={t('orders.partiallyCompleted')} parentCallback={handleCallBack} />} />
                    <Route path="/parcels-to-ship" element={<ParcelsToShip translation={t} parentCallback={handleCallBack} />} />
                    <Route path="/search-parcel" element={<SearchParcel translation={t} />} />
                    {/* <Route path="/orders-completed" children={OrdersCompleted(t)} /> */}
                    {/* <Route path="/users" children={Users(t)} /> */}
                    <Route path="/login" element={<Login parentCallback={handleCheckIfUserIsLogged} />} />
                </Routes>
            </div>
          </div>
      </Router>
  );
}

function App() {
  return (
    <div className="App">
      <Nav />
    </div>
  );
}

export default App;