import React from 'react';
import { getParcelsById } from '../services/functions';
import ParcelLine from './ParcelLine';

class SearchParcel extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.translation;
        this.state = {
            orderId: '',
            parcelsList: [],
            searchPending: false
        }
        this.searchParcel = this.searchParcel.bind(this);
    }

    /**
     * Recherche des colis correspondant au numéro de commande indiqué
     */
    searchParcel(e) {
        const that = this;
        const orderId = e.target.value;
        var isEven = false;
        
        if (orderId.length >= 3) {
            this.setState({searchPending: true});
            getParcelsById(orderId).then(function(res) {
                that.setState({searchPending: false});

                var parcels = [];

                for (var i = 0; i < res.parcels.length; i++) {
                    parcels.push(
                        <ParcelLine key={res.parcels[i]._id} trackingCode={res.parcels[i].trackingCode} orderId={res.parcels[i].orderId} dateCreated={res.parcels[i].dateCreated} address={res.parcels[i].shipping.firstName + ' ' + res.parcels[i].shipping.lastName + ' / ' + res.parcels[i].shipping.company + ' / ' + res.parcels[i].shipping.address1 + ' ' + res.parcels[i].shipping.address2 + ' ' + res.parcels[i].shipping.postCode + ' ' + res.parcels[i].shipping.city + ' (' + res.parcels[i].shipping.country + ')'} labelFileName={res.parcels[i].labelFileName} cn23FileName={res.parcels[i].cn23FileName} userId={res.parcels[i].userId} lineItems={res.parcels[i].lineItems} translation={that.t} isEven={isEven} />
                    );

                    if (isEven) {
                        isEven = false;
                    } else {
                        isEven = true;
                    }
                }

                that.setState({parcelsList: parcels});
            });
        } else {
            that.setState({parcelsList: []});
        }
    }

    render() {
        return(
            <div>
                <h1>{this.t('common.searchParcel')}</h1>
                <div><input className="searchInput" type="text" name="search" placeholder={this.t('common.searchParcelPlaceHolder')} onChange={this.searchParcel} /></div>
                <div>{this.state.searchPending ? <p>{this.t('common.searchPending')}</p> : ''}</div>
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>{this.t('common.trackingCode')}</th>
                            <th>{this.t('common.order')}</th>
                            <th>{this.t('common.date')}</th>
                            <th>{this.t('common.address')}</th>
                            <th>{this.t('common.preparedByUser')}</th>
                            <th>{this.t('common.actions')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.parcelsList}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default SearchParcel;