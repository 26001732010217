import React from 'react';

class ProductsProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.unit = 100 / props.maxQty;
        this.state = {
            progress: 0,
            progressValue: '',
            completed: false,
            preparedQty: props.preparedQty,
            initialQty: props.initialQty,
        }
        this.addProduct = this.addProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
    }

    addProduct() {
        this.props.parentCallBack({
            productId: this.props.productId,
            preparedQty: 1,
            maxQty: this.props.maxQty,
            weight: this.props.weight
        });
    }
    
    removeProduct() {
        this.props.parentCallBack({
            productId: this.props.productId,
            preparedQty: -1,
            maxQty: this.props.maxQty,
            weight: this.props.weight
        });
    }

    render() {
        return(
            <div className="ProductsProgressBar" value={this.state.preparedQty}>
                <div className="ProgressBar">
                    <span className={this.props.completed ? 'fill completed' : 'fill'} style={{width: this.props.progress}}>
                        <span className="productsNumber">{this.props.preparedQty}/{this.props.maxQty}</span>
                    </span>
                </div>
                <div>
                    <button onClick={this.removeProduct}>-</button>
                    <button onClick={this.addProduct}>+</button>
                </div>
            </div>
        );
    }
}

export default ProductsProgressBar;