import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageFR from './locate/fr/translate.json';

i18n
.use(initReactI18next)
.init({
    resources: {
        fr: languageFR,
    },
    lng: 'fr',
    fallbackLng: 'fr',
    debug: true,
    ns: ['translations'],
    defaultNS: "translations",
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});

export default i18n;