import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { DocumentTextIcon, EyeIcon, MailIcon } from '@heroicons/react/solid';
import { checkLoggedIn, getOrder, getUser } from '../services/functions';

Modal.setAppElement('#root');

class ParcelLine extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.translation;

        this.state = {
            parcel: {
                address: props.address,
                cn23FileName: props.cn23FileName,
                dateCreated: props.dateCreated,
                labelFileName: props.labelFileName,
                orderId: props.orderId,
                trackingCode: props.trackingCode,
                lineItems: props.lineItems
            },
            order: {},
            user: {
                id: props.userId
            },
            btnLabel: {
                text: this.t('common.label'),
                enabled: true,
                error: false
            },
            btnCn23Text: this.t('common.cn23'),
            modalIsOpenView: false,
        }

        this.isEven = this.props.isEven;

        this.downloadFile = this.downloadFile.bind(this);
    }
    
    componentDidMount() {
        this.getUserData();
    }

    getUserData() {
        const that = this;

        getUser(this.state.user.id).then(function(res) {
            if (res) {
                that.setState({
                    user: {
                        userId: that.state.user.id,
                        firstName: res.firstName,
                        lastName: res.lastName
                    }
                })
            }
        });
    }

    openModal(modalName) {
        const that = this;

        switch (modalName) {
            default:
            case 'view':
                getOrder(this.state.parcel.orderId).then(function(res) {
                    if (res !== undefined && res.lineItems !== undefined) {
                        that.setState({order: res});
                        that.setState(state => ({modalIsOpenView: true}));
                    }
                });
                break;
        }
    }

    closeModal(modalName) {
        switch (modalName) {
            default:
            case 'view':
                this.setState(state => ({
                    modalIsOpenView: false
                }));
                break;
        };
    }

    /**
     * Télécharge un fichier
     */
    async downloadFile(fileType, fileURL) {
        const that = this;
        const logged = await checkLoggedIn();

        if (logged.logged && logged.token && fileType) {
            this.setState(prevState => ({
                btnLabel: {
                    ...prevState.btnLabel,
                    enabled: false
                }
            }));
            
            await axios.get(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_DOWNLOAD, { headers: {'Content-Type': 'application/pdf;', 'x-access-token': logged.token }, responseType: 'blob', params: {fileName: that.state.parcel[fileType]} })
            .then(function(res) {
                const blobObj = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blobObj);
                window.open(url, '_blank');
            }).catch(error => {
                error.response.data.text().then(errorCode => {
                    console.log(error);

                    this.setState(prevState => ({
                        btnLabel: {
                            ...prevState.btnLabel,
                            text: that.t('errors.error'),
                            error: true
                        }
                    }));
                });
            });
        }
    }

    render() {
        return (
            <tr className={this.isEven ? 'rowEven' : 'rowOdd'}>
                <td>{this.state.parcel.trackingCode}</td>
                <td>{this.state.parcel.orderId}</td>
                <td>{this.state.parcel.dateCreated}</td>
                <td>{this.state.parcel.address}</td>
                <td>{this.state.user.firstName + ' ' + this.state.user.lastName}</td>
                <td>
                    <button onClick={() => this.openModal('view')}><EyeIcon className="icon" />{this.t('common.view')}</button>
                    <button className={this.state.btnLabel.error ? 'error' : ''} disabled={!this.state.btnLabel.enabled ? 'disabled' : ''} onClick={() => this.downloadFile('labelFileName', 'labelURL')}><DocumentTextIcon className="icon" />{this.state.btnLabel.text}</button>
                    {this.state.parcel.cn23FileName ? <button onClick={() => this.downloadFile('cn23FileName', 'cn23URL')}><MailIcon className="icon" />{this.state.btnCn23Text}</button> : ''}
                </td>

                <Modal
                    className="Modal"
                    overlayClassName="Overlay"
                    isOpen={this.state.modalIsOpenView}
                    onRequestClose={() => this.closeModal('view')}
                    shouldCloseOnOverlayClick={false}
                >
                    <h1>{this.t('common.order')} n°{this.state.parcel.orderId}</h1>
                    <h2>{this.t('parcels.preparedQtyParcel')}</h2>
                    {this.state.parcel.lineItems.map( item =>
                        <div key={item._id}>{item.name} - {item.qty} / {this.state.order.lineItems ? this.state.order.lineItems.find(element => element._id === item._id).qty : '-'}</div>
                    )}
                    <div className="buttons"><button className="close" onClick={() => this.closeModal('view')}>{this.t('common.close')}</button></div>
                </Modal>
            </tr>
        );
    }
}

export default ParcelLine;