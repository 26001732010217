import React from 'react';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLinks: [],
            pages: props.pages,
            currentPage: 1
        };
        this.t = props.translation;

        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }
    
    // TODO : récupérer le nombre de pages
    componentDidMount() {
        this.buildPagesArray();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pages !== this.props.pages) {
            this.state.pages = this.props.pages;
            this.buildPagesArray();
        }

        if (prevState.currentPage !== this.state.currentPage) {
            this.buildPagesArray();
        }
    }

    changePage = (data) => {
        this.setState({currentPage: data.target.value});
        this.props.parentCallBack(data.target.value);
    }

    prevPage() {
        const prevPage = this.state.currentPage - 1;

        if (this.state.currentPage - 1 >= 1 ) {
            this.setState({ currentPage: prevPage });
            this.changePage({target: {value: prevPage}});
        }
    }
    
    nextPage() {
        const nextPage = this.state.currentPage + 1;

        if (this.state.currentPage + 1 <= this.state.pages ) {
            this.setState({ currentPage: nextPage });
            this.changePage({target: {value: nextPage}});
        }
    }

    /**
     * Crée dynamiquement les liens des numéros de pages
     */
    buildPagesArray() {
        const maxLinks = 7;
        const offset = 3;
        
        var i = 0;
        var pagesArray = [];

        // Crée la liste des liens
        for (i = -offset; i <= offset; i++) {
            if ( this.state.currentPage + i >= 1 && this.state.currentPage <= this.state.pages ) {
                if (this.state.currentPage + i <= this.state.pages) {
                    pagesArray.push(this.state.currentPage + i);
                }
            }
        }
        
        // Crée les liens manquants éventuels (en début ou fin de liste)
        if (pagesArray.length < maxLinks && this.state.pages >= maxLinks) {
            if (this.state.currentPage < this.state.pages - offset) {
                // Crée les liens manquants en début de liste
                for (i = pagesArray.length; i < maxLinks; i++ ) {
                    pagesArray.push(1 + i);
                }
            } else {
                // Crée les liens manquants en début de liste
                for (i = pagesArray.length; i < maxLinks; i++ ) {
                    pagesArray.unshift(this.state.pages - i);
                }
            }
        }

        this.setState({ pageLinks: pagesArray });
    }

    render() {
        var that = this;
        var pagesList = this.state.pageLinks.map(function(page) {
            return <li key={page} value={page} onClick={that.changePage} className={page === that.state.currentPage ? 'active' : ''}>{page}</li>
        });

        return (
            <div className="Pagination">
                <ul>
                    <button onClick={this.prevPage}>{this.t('common.previous')}</button>
                    { pagesList }
                    <button onClick={this.nextPage}>{this.t('common.next')}</button>
                </ul>
                <p>{this.state.pages} {this.t('common.pages')}</p>
            </div>
        );
    }
}

export default Pagination;