import React from 'react';

class SelectCountries extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.translation;
        this.state = {
            value: props.value
        }
        this.handleChange = this.handleChange.bind(this);
        this.countries = ["AF","ZA","AL","DZ","DE","AD","AO","AI","AQ","AG","AN","SA","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BA","BW","BR","BN","BG","BF","BI","CM","CA","CV","EA","CL","CN","CY","CO","KM","CG","KP","KR","CR","CI","HR","CU","DK","DG","DJ","DM","EG","SV","AE","EC","ER","ES","EE","VA","FM","US","ET","FJ","FI","FR","GA","GM","GE","GS","GH","GI","GR","GD","GL","GP","GU","GT","GG","GN","GQ","GW","GY","GF","HT","HN","HU","BV","CX","CP","AC","IM","NF","AX","KY","IC","CC","CK","FO","HM","FK","MP","MH","UM","SB","TC","VG","VI","IN","ID","IQ","IR","IE","IS","IL","IT","JM","JP","JE","JO","KZ","KE","KG","KI","KW","LA","LS","LV","LB","LR","LY","LI","LT","LU","MK","MG","MY","MW","MV","ML","MT","MA","MQ","MU","MR","YT","MX","MD","MC","MN","ME","MS","MZ","MM","NA","NR","NP","NI","NE","NG","NU","NO","NC","NZ","OM","UG","UZ","PK","PW","PA","PG","PY","NL","PE","PH","PN","PL","PF","PR","PT","QA","HK","MO","QO","CF","CD","DO","CZ","RE","RO","GB","RU","RW","EH","BL","KN","SM","MF","PM","VC","SH","LC","WS","AS","ST","SN","RS","CS","SC","SL","SG","SK","SI","SO","SD","LK","SE","CH","SR","SJ","SZ","SY","TJ","TW","TZ","TD","TF","IO","PS","TH","TL","TG","TK","TO","TT","TA","TN","TM","TR","TV","UA","EU","UY","VU","VE","VN","WF","YE","ZM","ZW"]
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
        this.props.parentCallBack({ value: e.target.value });
    }

    render() {
        return(
            <select value={this.state.value} onChange={this.handleChange}>
                {this.countries.map((key) => (
                    <option key={key} value={key}>{this.t('countries.' + key)}</option>
                ))}
            </select>
        );
    }
}

export default SelectCountries;