import React from 'react';
import { gql, GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';
import checkLoggedIn from '../services/functions';
import { withTranslation } from 'react-i18next';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { logged: false };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
    }

    componentDidMount() {
        const that = this;
        checkLoggedIn().then(function (res) {
            that.setState({logged: res.logged});
        });
    }

    componentDidUpdate() {
        this.props.parentCallback({logged: this.state.logged });
    }

    /**
     * Envoi le formulaire pour une tentative de connection
     */
    async handleFormSubmit(e) {
        e.preventDefault();
        console.log(e.target.email.value);
        const that = this;
        const email = e.target.email.value;
        const password = e.target.password.value;
        const companyId = e.target.companyId.value;

        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_LOGIN, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const mutation = gql`
            query {
                loginUser(
                    user: {
                        email: "${email}",
                        password: "${password}",
                        companyId: "${companyId}"
                    }
                )
                {
                    token
                }
            }
        `;

        const variables = {
            email: email,
            password: password,
            companyId: companyId
        };

        const data = await graphQLClient.request(mutation, variables);
        const token = data.loginUser.token;

        // document.cookie = "token=" + token + "; SameSite=None; Secure";
        Cookies.set('token', token);

        checkLoggedIn().then(function(result) {
            that.setState({ logged: result.logged });
        });
    }

    /**
     * Déconnecte un utilisateur
     */
    logoutUser() {
        Cookies.remove('token');
        this.setState({logged: false});
    }
    
    /**
     * Affiche soit le formulaire de login si l'utilisateur n'est pas connecté
     * Sinon affiche un formulaire de déconnexion
     */
    render () {
        return (
            <div>
                {
                    this.state.logged ?
                    <div>
                        <h1>{this.props.t('login.logout')}</h1>
                        <p>{this.props.t('login.askToLogout')}</p>
                        <div>
                            <button onClick={this.logoutUser}>{this.props.t('login.yesLogout')}</button>
                        </div>
                    </div>
                    :
                    <div className="Login">
                        <h1>{this.props.t('login.login')}</h1>
                        <form onSubmit={this.handleFormSubmit}>
                            <input type="text" name="email" placeholder={this.props.t('login.userName')} />
                            <input type="password" name="password" placeholder={this.props.t('login.password')} />
                            <input type="text" name="companyId" placeholder={this.props.t('common.companyId')} />
                            <button type="submit">{this.props.t('login.login')}</button>
                        </form>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(Login);