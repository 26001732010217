import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import ProductsProgressBar from './ProductsProgressBar';
import { CubeIcon, DocumentTextIcon, EyeIcon, LockClosedIcon, MailIcon, PencilAltIcon } from '@heroicons/react/solid';
import { checkLoggedIn, getOrder, updateShippingAddress, createParcel } from '../services/functions';
import SelectCountries from './CountriesSelect';
// import { type } from '@testing-library/user-event/dist/type';

Modal.setAppElement('#root');

class OrderLine extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.translation;
        this.countries = ["AF","ZA","AL","DZ","DE","AD","AO","AI","AQ","AG","AN","SA","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BA","BW","BR","BN","BG","BF","BI","CM","CA","CV","EA","CL","CN","CY","CO","KM","CG","KP","KR","CR","CI","HR","CU","DK","DG","DJ","DM","EG","SV","AE","EC","ER","ES","EE","VA","FM","US","ET","FJ","FI","FR","GA","GM","GE","GS","GH","GI","GR","GD","GL","GP","GU","GT","GG","GN","GQ","GW","GY","GF","HT","HN","HU","BV","CX","CP","AC","IM","NF","AX","KY","IC","CC","CK","FO","HM","FK","MP","MH","UM","SB","TC","VG","VI","IN","ID","IQ","IR","IE","IS","IL","IT","JM","JP","JE","JO","KZ","KE","KG","KI","KW","LA","LS","LV","LB","LR","LY","LI","LT","LU","MK","MG","MY","MW","MV","ML","MT","MA","MQ","MU","MR","YT","MX","MD","MC","MN","ME","MS","MZ","MM","NA","NR","NP","NI","NE","NG","NU","NO","NC","NZ","OM","UG","UZ","PK","PW","PA","PG","PY","NL","PE","PH","PN","PL","PF","PR","PT","QA","HK","MO","QO","CF","CD","DO","CZ","RE","RO","GB","RU","RW","EH","BL","KN","SM","MF","PM","VC","SH","LC","WS","AS","ST","SN","RS","CS","SC","SL","SG","SK","SI","SO","SD","LK","SE","CH","SR","SJ","SZ","SY","TJ","TW","TZ","TD","TF","IO","PS","TH","TL","TG","TK","TO","TT","TA","TN","TM","TR","TV","UA","EU","UY","VU","VE","VN","WF","YE","ZM","ZW"];
        this.timer = null;

        this.closeModal = this.closeModal.bind(this);
        this.createNewParcel = this.createNewParcel.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.enablePartialValidation = this.enablePartialValidation.bind(this);
        // this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleShippingAmount = this.handleShippingAmount.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handlePreparedQty = this.handlePreparedQty.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.openModal = this.openModal.bind(this);
        this.prepareProductsList = this.prepareProductsList.bind(this);
        this.renderMessages = this.renderMessages.bind(this);
        this.updateTheShippingAddress = this.updateTheShippingAddress.bind(this);

        this.state = {
            barCodeInput: '',
            btnValidateText: this.t('order.validateAndGenerateDocs'),
            btnValidateStatus: 'disabled',
            labelFileName: '',
            labelURL: '',
            cn23FileName: '',
            cn23URL: '',
            messagesBoxState: 'messagesSuccess',
            messages: '',
            modalIsOpenView: false,
            modalIsOpenShipping: false,
            modalIsOpenPrepare: false,
            checkLockedPaused: true,
            disableValidation: true,
            checkPartial: false,
            disableCheckPartial: true,
            preparedItems: [],
            order: props.order,
            products: '',
            initialPreparedQty: 0,  // Quantité des articles déjà ajoutés dans des colis précédents
            currentPreparedQty: 0,  // Quantité des articles en train d'être ajoutés au colis actuellement en préparation
            preparedQty: 0,
            totalQtyToPrepareOnOrder: 0,
            parcelWeight: props.order.emptyParcelWeight,
            convertedParcelWeight: props.order.emptyParcelWeight / 1000
        }

        this.barCodeAllowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Enter']; // Valeurs autorisées quand on scanne un code barre
        this.barCode = '';
    }

    componentDidMount() {
        // const that = this;
        // this.timer = setInterval(function() {
        //     if (!that.state.checkLockedPaused) {
        //         orderLocked(that.state.order._id).then(function(res) {
        //             if (res) { that.closeModal('prepare'); }
        //         });
        //     }
        // }, 2000);

        // document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        // document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (this.barCodeAllowedValues.includes(e.key)) {
            if (e.key === 'Enter' && this.barCode.length === 13) {
                this.handlePreparedQty({
                    barCode: this.barCode,
                    preparedQty: 1
                })
                this.barCode = '';
            } else {
                this.barCode += e.key;
            }
        }
    }

    prepareProductsList() {
        var preparedItemQty = 0;
        var totalPreparedqty = 0;
        var totalInitialPreparedQty = 0;
        var itemsToAdd = [];

        this.state.order.lineItems.forEach(item => {

            // if (this.state.order.prepared === 'NO') { // Condition pour inverser la préparation des produits (qté de produits max déjà sélectionnée au lieu de les ajouter petit à petit)
            //     preparedItemQty = item.qty;
            // } else {
            //     preparedItemQty = item.preparedQty;
            // }

            preparedItemQty = item.preparedQty;

            const unit = 100 / item.qty;
            const progress = unit * preparedItemQty + '%';

            var completed = false;
            if (preparedItemQty === item.qty) { completed = true; }

            const newItem = {
                id: parseInt(item._id),
                name: item.name,
                qty: item.qty,
                initialQty: item.preparedQty,
                preparedQty: preparedItemQty,
                newPreparedQty: 0,
                barCode: item.barCode,
                progress: progress,
                completed: completed,
                weight: item.weight
            }

            itemsToAdd.push(newItem);

            totalPreparedqty += preparedItemQty;
            totalInitialPreparedQty += item.preparedQty;
        });

        this.setState({
            preparedItems: itemsToAdd,
            preparedQty: totalPreparedqty,
            initialPreparedQty: totalInitialPreparedQty
        });

        // Rend disponible le bouton de validation si la commande n'est pas du tout préparée
        if (this.state.order.prepared && this.state.order.prepared === 'NO' && this.state.preparedQty < this.state.initialPreparedQty) {
            this.setState({
                disableValidation: false,
                btnValidateStatus: 'available',
                disableCheckPartial: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.order !== this.props.order) {
            this.setState({ order: this.props.order});
        }
    }

    updateTheShippingAddress(orderId = 0) {
        const that = this;
        
        const shippingAddress = {
            firstName: '"' + this.state.order.shipping.firstName + '"',
            lastName: '"' + this.state.order.shipping.lastName + '"',
            address1: '"' + this.state.order.shipping.address1 + '"',
            address2: '"' + this.state.order.shipping.address2 + '"',
            address3: '"' + this.state.order.shipping.address3 + '"',
            address4: '"' + this.state.order.shipping.address4 + '"',
            postCode: '"' + this.state.order.shipping.postCode + '"',
            city: '"' + this.state.order.shipping.city + '"',
            country: '"' + this.state.order.shipping.country + '"',
            phone: '"' + this.state.order.shipping.phone + '"',
        };

        // Met à jour l'adresse en base de données
        updateShippingAddress(orderId, shippingAddress).then(function(res) {
            if (res) {
                // Met à jour l'adresse dans le composant
                that.setState( prevState => ({
                    order: {
                        ...prevState.order,
                        shipping: {
                            shippingAddress
                        }
                    }
                }));

                that.closeModal('shipping');
            }
        });
    }

    /**
     * Crée un colis (génération des étiquettes)
     */
    createNewParcel(e) {
        const that = this;

        this.setState({
            disableValidation: true,
            btnValidateStatus: 'disabled',
            messagesBoxState: 'messagesWarning',
            messages: [this.t('messages.generatingDocuments')]
        });
        
        // Simplifie la liste des produits préparés pour l'API ui va générer le colis
        var simplifiedPreparedItemsList = [];

        this.state.preparedItems.forEach(item => {
            if (item.preparedQty > 0) {
                simplifiedPreparedItemsList = {
                    ...simplifiedPreparedItemsList,
                    [item.id]: item.newPreparedQty
                }
            }
        })

        var shippingAmountToSend = this.state.shippingAmount;

        if (!shippingAmountToSend) { shippingAmountToSend = 0; }

        createParcel(this.state.order._id, simplifiedPreparedItemsList, shippingAmountToSend)
        .then(function(res) {
            if (res && res.trackingCode) {
                that.setState({
                    messages: [that.t('messages.documentsGenerated')],
                    btnValidateStatus: 'done',
                    messagesBoxState: 'messagesSuccess',
                    labelFileName: res.labelFileName,
                    cn23FileName: res.cn23FileName
                });
            } else {
                that.setState({
                    messages: [that.t('errors.errorGeneratingLabel')],
                    btnValidateStatus: 'done',
                    messagesBoxState: 'messagesError'
                });
            }
        }).catch(error => {
                var messages = [];

                if (error.response) {
                    error.response.errors.forEach(item => {
                        if(this.state.order.shipping.carrierId === 'mondial_relay') {
                            messages.push(this.t('mondial_relay_errors.' + item.message) + ' (code ' + item.message + ')');
                        } else {
                            messages.push(item.message);
                        }
                    });
                } else {
                    console.log(error);
                }

                that.setState({
                    messages: messages,
                    messagesBoxState: 'messagesError',
                    btnValidateStatus: 'done'
                });
            }
        );
    }

    /**
     * Télécharge un fichier
     */
    async downloadFile(fileType, fileURL) {
        const that = this;
        const logged = await checkLoggedIn();

        if (logged.logged && logged.token && fileType) {
            that.setState({messagesBoxState: 'messagesWarning'});
            that.setState({messages: [that.t('messages.downloading')]});
            
            await axios.get(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_DOWNLOAD, { headers: {'Accept': 'application/pdf;application/json', 'x-access-token': logged.token }, responseType: 'blob', params: {fileName: this.state[fileType]} })
            .then(function(res) {
                const blobObj = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blobObj);
                window.open(url, '_blank');
                that.setState({ [fileURL]: url });
                that.setState({messagesBoxState: 'messagesSuccess'});
                that.setState({messages: [that.t('messages.downloadDone')]});
            }).catch(error => {
                error.response.data.text().then(errorCode => {
                    that.setState({messages: [that.t('errors.' + errorCode)]});
                    that.setState({messagesBoxState: 'messagesError'});
                    that.setState({btnValidateStatus: 'done'});
                });
            });
        }
    }

    openModal(modalName, orderId = 0) {
        const that = this;

        switch (modalName) {
            default:
                case 'view':
                this.setState({ modalIsOpenView: true });
                // getOrder(orderId).then(function(res) {
                //     that.setState({ order: res });
                // });
                break;
            
            case 'shipping':
                this.props.parentCallBack({refreshPaused: true});
                this.setState(state => ({
                    modalIsOpenShipping: true
                }));
                getOrder(orderId).then(function(res) {
                    that.setState({ order: res });
                });
                break;
                        
            case 'prepare':
                this.props.parentCallBack({refreshPaused: true});
                getOrder(orderId, 'lock').then(function(res) {
                    that.setState({ order: res }, function() {
                        // Calcul la quantité totale de produits à préparer
                        var totalQtyToPrepareOnOrder = 0;
                        this.state.order.lineItems.forEach(item => { totalQtyToPrepareOnOrder += item.qty; });

                        this.setState({
                            modalIsOpenPrepare: true,
                            totalQtyToPrepareOnOrder: totalQtyToPrepareOnOrder,
                            currentPreparedQty: 0,
                            parcelWeight: this.props.order.emptyParcelWeight,
                            convertedParcelWeight: this.props.order.emptyParcelWeight / 1000                
                        }, () => {
                            // this.setState({preparedQty: newPreparedQty});
    
                            // TODO : Rendre disponible ou non le bouton de validation

                            if (that.state.initialPreparedQty + that.state.preparedQty === totalQtyToPrepareOnOrder) {
                                this.setState({
                                    disableValidation: false,
                                    btnValidateStatus: 'available',
                                })
                            } else {
                                this.setState({
                                    disableValidation: true,
                                    btnValidateStatus: 'disabled',
                                })
                            }
                        });

                        that.prepareProductsList();
                        document.addEventListener("keydown", this.handleKeyDown);
                    });
                });
                break;
        };
    }

    closeModal(modalName) {
        const that = this;
        const orderId = this.state.order._id;

        switch (modalName) {
            default:
            case 'view':
                this.setState(state => ({
                    modalIsOpenView: false
                }));
                break;
                
            case 'shipping':
                this.props.parentCallBack({refreshPaused: false});
                this.setState(state => ({
                    modalIsOpenShipping: false
                }));
                getOrder(orderId).then(function(res) {
                    that.setState({ order: res });
                });
                break;

            case 'prepare':
                document.removeEventListener("keydown", this.handleKeyDown);
        
                this.props.parentCallBack({refreshPaused: false});
                this.setState({
                    checkLockedPaused: true,
                    modalIsOpenPrepare: false
                });

                // Rafraichit les données
                getOrder(that.state.order._id, 'unlock').then(function(res) {
                    that.setState({ order: res });
                });           
                break;
        };
    }
    
    /**
     * Récupère les valeurs de retour de chaque composant pour calculer si la quantité totale est validée
     * pour rendre le bouton de validation disponible
     * 
     * @param {Object} e
     */
     handlePreparedQty(e) {
        // Construit la liste des produits préparés pour la requête de création de colis
        if ((e.productId || e.barCode) && e.preparedQty) {
            this.setState({messagesBoxState: ''});
            this.setState({messages: ''});
            var preparedItems = this.state.preparedItems;
            var newCurrentPreparedQty = this.state.currentPreparedQty;
            var newParcelWeight = this.state.parcelWeight;
            var productBarCodeFound = false;
            
            // Recherche la quantité préparée initiale
            for (var i = 0; i < preparedItems.length; i++) {
                if (preparedItems[i].id === e.productId || preparedItems[i].barCode === e.barCode) {
                    // Met à jour la quantité
                    const qtyReceived = e.preparedQty;

                    if (preparedItems[i].newPreparedQty + qtyReceived >= 0 && preparedItems[i].preparedQty + qtyReceived <= preparedItems[i].qty ) {
                        preparedItems[i].newPreparedQty += qtyReceived;
                        preparedItems[i].preparedQty += qtyReceived;

                        if (qtyReceived > 0) {
                            newParcelWeight += preparedItems[i].weight;
                        } else if (qtyReceived < 0) {
                            newParcelWeight -= preparedItems[i].weight;
                        }

                        // Met à jour le statut de la barre de progression
                        if (preparedItems[i].preparedQty === preparedItems[i].qty) {
                            preparedItems[i].progress = '100%';
                            preparedItems[i].completed = true;
                        } else {
                            preparedItems[i].progress = preparedItems[i].preparedQty * (100 / preparedItems[i].qty) + '%';
                            preparedItems[i].completed = false;
                        }

                        newCurrentPreparedQty += qtyReceived;
                        productBarCodeFound = true;
                    } else if (preparedItems[i].preparedQty + qtyReceived >= preparedItems[i].qty) {
                        this.setState({messagesBoxState: 'messagesError'});
                        this.setState({messages: [this.t('errors.quantityAlreadyComplete')]});
                        productBarCodeFound = true;
                    };

                    break;
                }
            }

            if (!productBarCodeFound) {
                // if (e.barCode !== undefined && e.barCode !== null && preparedItems[i].barCode !== e.barCode) { // Code barre inconnu, on affiche une alerte
                this.setState({messagesBoxState: 'messagesError'});
                this.setState({messages: [this.t('errors.barcodeUnknown')]});
                // }
            }

            // Calcule la nouvelle quantité
            const oldQty = this.state.currentPreparedQty;

            if (newCurrentPreparedQty < oldQty) {
                this.setState({
                    disableValidation: true,
                    btnValidateStatus: 'disabled'
                });
            }
    
            if (this.state.totalQtyToPrepareOnOrder === (parseInt(this.state.initialPreparedQty) + newCurrentPreparedQty)) {
                // La quantité de produits est complète, on rend disponible le bouton de validation
                this.setState({
                    disableCheckPartial: true,
                    disableValidation: false,
                    btnValidateStatus: 'available',
                });
            } else {
                // if (newCurrentPreparedQty === 0 || newCurrentPreparedQty <= this.state.initialPreparedQty) {
                if (newCurrentPreparedQty > 0) {
                    this.setState({
                        disableCheckPartial: false,
                    });
                } else {
                    this.setState({
                        disableCheckPartial: true,
                    });
                }
            }

            this.setState({
                preparedItems: preparedItems,
                currentPreparedQty: newCurrentPreparedQty,
                checkPartial: false,
                parcelWeight: newParcelWeight,
                convertedParcelWeight: newParcelWeight / 1000
            });
        }
    };

    /**
     * Récupère les valeurs des champs "input" dynamiquement
     * 
     * @param {Object} e
     */
    handleChange(e) {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                shipping: {
                    ...prevState.order.shipping,
                    [e.target.name]: e.target.value
                },
            }
        }));
    }

    /**
     * Récupère les valeurs des champs "input" dynamiquement
     * 
     * @param {Object} e
     */
    handleShippingAmount(e) {
        var newShippingAmount = parseFloat(e.target.value);
        this.setState({ shippingAmount: newShippingAmount });
    }

    /**
     * Permet de rendre disponible le bouton de validation pour l'envoi partiel de colis
     * 
     * @param {Object} e
     */
    enablePartialValidation(e) {
        if (e.target.checked) {
            this.setState(state => ({
                disableValidation: false,
                btnValidateStatus: 'available',
                checkPartial: true
            }));
        } else {
            this.setState(state => ({
                disableValidation: true,
                btnValidateStatus: 'disabled',
                checkPartial: false
            }));
        }
    }

    /**
     * Met en forme les messages d'erreur
     */
    renderMessages() {
        return Object.entries(this.state.messages).map(([key, value]) => {
            return(
                <div key={'message' + key}>{value}</div>
            )
        });
    }

    /**
     * Gère le choix du pays
     */
    handleCountry(e) {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                shipping: {
                    ...prevState.order.shipping,
                    country: e.value }
                }
            }));
    }

    /**
     * Vérifie si la commande est verrouillée pour préparation
     */
    orderIsLocked() {
        // orderLocked(this.state.order._id).then(function(res) {
        //     console.log('orderIsLocked = ' + res);
        // });
        if (this.state.order && this.state.order.locked && this.state.order.locked.userId && this.state.order.locked.timestamp) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <tr className={this.orderIsLocked() ? 'orderLineLocked' : this.props.isEven ? 'rowEven' : 'rowOdd'}>
                <td>{this.state.order.status ? this.t('orders.statuses.' + this.state.order.prepared) : ''}</td>
                <td>
                    <span>{this.state.order._id}</span>
                    <button onClick={() => this.openModal('view', this.state.order._id)} name="view"><EyeIcon className="icon" /> {this.t('common.view')}</button>
                    <span className={this.state.order.customerNote ? 'warningSpot' : ''}></span>
                </td>
                <td>{this.state.order.dateCreated}</td>
                <td>#{this.state.order.customer ? this.state.order.customer._id : '(unknown)'} {this.state.order.customer ? this.state.order.customer.firstName : '(unknown)'} {this.state.order.customer ? this.state.order.customer.lastName : '(unknown)'}</td>
                <td className="address"><span>{this.state.order && this.state.order.shipping && this.state.order.shipping.city ? this.state.order.shipping.city :''} ({this.state.order && this.state.order.shipping && this.state.order.shipping.country ? this.state.order.shipping.country : ''})</span></td>
                <td>
                    <button onClick={() => this.openModal('shipping', this.state.order._id)}><PencilAltIcon className="icon" />{this.t('shipping.editShippingAddressShort')}</button>                    
                    <button onClick={() => this.openModal('prepare', this.state.order._id)}><CubeIcon className="icon" />{this.t('common.prepare')}</button>
                    {this.orderIsLocked() ? <LockClosedIcon className="icon" /> : ''}
                </td>
                
                <Modal
                    className="Modal"
                    overlayClassName="Overlay"
                    isOpen={this.state.modalIsOpenView}
                    onRequestClose={() => this.closeModal('view')}
                    shouldCloseOnOverlayClick={false}
                >
                    <h1>{this.t('common.order')} n°{this.state.order._id} - {this.state.order.status ? this.t('orders.statuses.' + this.state.order.prepared) : ''}</h1>
                    <p>{this.t('common.date')} : {this.state.order.dateCreated}</p>
                    {this.state.order.customerNote ?                        
                        <div className="customerNote">
                            <h2>{this.t('order.customerNote')}<span className="warningSpot"></span></h2>
                            <p>{this.state.order.customerNote}</p>
                        </div>
                    : ''}
                    {this.state.order.customer ?
                        <div>
                            <h2>{this.t('common.customer')}</h2>
                            <p>{this.state.order.customer._id}</p>
                            <p>{this.state.order.customer.firstName}</p>
                            <p>{this.state.order.customer.lastName}</p>
                            <p>{this.state.order.customer.email}</p>
                        </div>
                        : ''}
                    {this.state.order.shipping && this.state.order.shipping.carrierId ?
                        <div>
                            <h2>{this.t('carrier.carrier')}</h2>
                            <p>{this.state.order.shipping.carrierId ? this.t('carrier.carrier') + ' : ' + this.t('carrier.' + this.state.order.shipping.carrierId) : ''}</p>
                            <p>{this.state.order.shipping.method ? this.t('shipping.method') + ' : ' + this.t('shipping.methods.' + this.state.order.shipping.method) : ''}</p>
                        </div>
                        : ''}
                    {this.state.order.shipping ?
                        <div>
                            <h2>{this.t('common.shippingAddress')}</h2>
                            <p>{this.state.order.shipping.company}</p>
                            <p>{this.state.order.shipping.firstName}</p>
                            <p>{this.state.order.shipping.lastName}</p>
                            <p>{this.state.order.shipping.address1}</p>
                            <p>{this.state.order.shipping.address2}</p>
                            <p>{this.state.order.shipping.address3}</p>
                            <p>{this.state.order.shipping.address4}</p>
                            <p>{this.state.order.shipping.postCode}</p>
                            <p>{this.state.order.shipping.city}</p>
                            <p>{this.state.order.shipping.state}</p>
                            <p>{this.state.order.shipping.country}</p>
                            <p>{this.state.order.shipping.phone}</p>
                            <p>{this.state.order.shipping.email}</p>
                        </div>
                        : ''}
                    <div className="buttons">
                        <button className="close" onClick={() => this.closeModal('view')}>{this.t('common.close')}</button>
                    </div>
                </Modal>

                <Modal
                    className="Modal"
                    overlayClassName="Overlay"
                    isOpen={this.state.modalIsOpenShipping}
                    onRequestClose={() => this.closeModal('shipping')}
                    shouldCloseOnOverlayClick={false}
                >
                    <h1>{this.t('shipping.editShippingAddress')} - {this.t('common.order')} n°{this.state.order._id}</h1>
                    
                    {this.t('shipping.method') + ' : ' + this.t('shipping.methods.' + this.state.order.shipping.method)}
                    <form>
                        <div>
                            <label>{this.t('user.firstname')}</label><input type="text" name="firstName" onChange={this.handleChange} value={this.state.order.shipping && this.state.order.shipping.firstName ? this.state.order.shipping.firstName : ''} />
                        </div>

                        <div>
                            <label>{this.t('user.lastname')}</label><input type="text" name="lastName" onChange={this.handleChange} value={this.state.order.shipping && this.state.order.shipping.lastName ? this.state.order.shipping.lastName : ''} />
                        </div>

                        <div>
                            <label>{this.t('shipping.street')}</label><input type="text" name="address1" onChange={this.handleChange} value={this.state.order.shipping && this.state.order.shipping.address1 ? this.state.order.shipping.address1 : ''} />
                        </div>

                        <div>
                            <label></label><input type="text" name="address2" onChange={this.handleChange} value={this.state.order.shipping && this.state.order.shipping.address2 ? this.state.order.shipping.address2 : ''} />
                        </div>

                        <div>
                            <label>{this.t('shipping.postCode')}</label><input type="text" name="postCode" onChange={this.handleChange}  value={this.state.order.shipping && this.state.order.shipping.postCode ? this.state.order.shipping.postCode : ''} />
                        </div>

                        <div>
                            <label>{this.t('shipping.city')}</label><input type="text" name="city" onChange={this.handleChange}  value={this.state.order.shipping && this.state.order.shipping.city ? this.state.order.shipping.city : ''} />
                        </div>

                        <div>
                            <label>{this.t('shipping.phone')}</label><input type="text" name="phone" onChange={this.handleChange} value={this.state.order.shipping && this.state.order.shipping.phone ? this.state.order.shipping.phone : ''} />
                        </div>

                        <div>
                            <label>{this.t('shipping.country')}</label>
                            <SelectCountries value={this.state.order.shipping ? this.state.order.shipping.country : ''} parentCallBack={this.handleCountry} translation={this.t} />
                        </div>
                    </form>

                    {this.state.order.customer ?
                        <div>
                            <h3>{this.t('common.customerAccountAddress')} :</h3>
                            <p>{this.state.order.customer.shipping.firstName}</p>
                            <p>{this.state.order.customer.shipping.lastName}</p>
                            <p>{this.state.order.customer.shipping.address1}</p>
                            <p>{this.state.order.customer.shipping.address2}</p>
                            <p>{this.state.order.customer.shipping.address3}</p>
                            <p>{this.state.order.customer.shipping.address4}</p>
                            <p>{this.state.order.customer.shipping.postCode}</p>
                            <p>{this.state.order.customer.shipping.city}</p>
                            <p>{this.state.order.customer.shipping.country}</p>
                        </div>
                        : '(unknown)'
                    }

                    <div className="buttons">
                        <button className="close" onClick={() => this.closeModal('shipping')}>{this.t('common.cancel')}</button>
                        <button className="validate" onClick={() => this.updateTheShippingAddress(this.state.order._id)}>{this.t('common.validate')}</button>
                    </div>
                </Modal>

                <Modal
                    className="Modal orderPrepare"
                    overlayClassName="Overlay"
                    isOpen={this.state.modalIsOpenPrepare}
                    onRequestClose={() => this.closeModal('prepare')}
                    shouldCloseOnOverlayClick={false}
                >
                    <h1>{this.t('order.orderPrepare')} n° {this.state.order._id} - {this.state.order.status ? this.t('orders.statuses.' + this.state.order.prepared) : ''}</h1>

                    {this.state.order.customerNote ?                    
                        <div className="customerNote">
                            <h2>{this.t('order.customerNote')}<span className="warningSpot"></span></h2>
                            <p>{this.state.order.customerNote}</p>
                        </div>
                    : ''}

                    <ul className="products">
                        {this.state.preparedItems.map((item, index) => {
                            return (<li key={item.id}><p>{item.name}</p><ProductsProgressBar productId={item.id} initialQty={item.initialQty} preparedQty={item.preparedQty} maxQty={item.qty} progress={item.progress} completed={item.completed} weight={item.weight} parentCallBack={this.handlePreparedQty} /></li>)
                        })}
                    </ul>

                    { this.state.order.shipping.needcn23 ?
                    <div>
                        <label>{this.t('order.shippingAmount')}&nbsp;</label>
                        <input type="number" name="shippingAmount" value={this.state.shippingAmount ? this.state.shippingAmount : ''} onChange={this.handleShippingAmount} />
                    </div>
                    : '' }

                    <div>
                        <span>{this.t('common.parcelWeight')} : </span><span>{this.state.convertedParcelWeight} kg</span>
                    </div>

                    <div className="buttons">
                        <label className="inline-flex items-center">
                            <input type="checkbox" name="forcePartial" onChange={e => this.enablePartialValidation(e)} checked={this.state.checkPartial ? 'checked' : ''} disabled={this.state.disableCheckPartial ? 'disabled' : ''} />
                            <span className={this.state.disableCheckPartial ? 'labelDisabled' : ''}>{this.t('order.forcePartialShipping')}</span>
                        </label>
                        <button className={'validate ' + this.state.btnValidateStatus} disabled={this.state.disableValidation} onClick={() => this.createNewParcel()}>{this.state.btnValidateText}</button>
                        <button className="close" onClick={() => this.closeModal('prepare')}>{this.t('common.close')}</button>
                    </div>

                    <div className="buttons">
                        {this.state.labelFileName ? <button onClick={() => this.downloadFile('labelFileName', 'labelURL')}><DocumentTextIcon className="icon" />{this.t('common.label')}</button> : ''}
                        {this.state.cn23FileName ? <button onClick={() => this.downloadFile('cn23FileName', 'cn23URL')}><MailIcon className="icon" />{this.t('common.cn23')}</button> : ''}
                    </div>

                    {this.state.messages ? <div className={'messagesBox ' + this.state.messagesBoxState}>{this.renderMessages()}</div> : ''}
                </Modal>
            </tr>
        );
    }
}

export default OrderLine;