import Cookies from 'js-cookie';
import { gql, GraphQLClient } from 'graphql-request';

/**
 * Vérifie si un utilisateur est connecté (si un token est trouvé et qu'il est valide)
 */
async function checkLoggedIn() {
    const token = Cookies.get('token');
    var logged = false;
    var company = '';

    if (token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_LOGIN, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    
        const query = gql`
            query {
                checkToken(token: "${token}")
                {
                    code,
                    message,
                    currentCompany
                }
            }
        `;

        const variables = {
            token: token
        };
    
        const data = await graphQLClient.request(query, variables);
        const response = data.checkToken;
    
        if (response.code === 'success') {
            logged = true;
            company = response.currentCompany;
        }
    }

    const result = {
        logged: logged,
        token: token,
        company: company
    }

    return result;
}

/**
 * Récupère la liste de commandes selon leur statut
 */
async function getOrders(page, status, preparedStatus, orderId) {
    const logged = await checkLoggedIn();
    var data = { getAllOrders: [] };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        var filters = {};

        if (page !== undefined) {
            filters.page = page;
        }

        if (status !== undefined) {
            filters.status = status;
        }

        if (preparedStatus !== undefined) {
            filters.preparedStatus = preparedStatus;
        }

        if (orderId !== undefined) {
            filters.orderId = orderId;
        }

        const query = gql`
            query {
                getAllOrders(
                    ${filters.page ? 'page: ' + filters.page + ',' : ''}
                    ${filters.status ? 'status: "' + filters.status + '",' : ''}
                    ${filters.preparedStatus ? 'preparedStatus: "' + filters.preparedStatus + '",' : ''}
                    ${filters.orderId ? 'orderId: ' + filters.orderId + ',' : ''}
                )
                {
                    orders {
                        _id
                        dateCreated
                        status
                        prepared
                        customerNote
                        prepared
                        emptyParcelWeight
                        customer {
                            _id
                            firstName
                            lastName
                            email
                            shipping {
                                company
                                firstName
                                lastName
                                address1
                                address2
                                postCode
                                city
                                state
                                country
                                email
                                phone
                            }
                        }
                        shipping {
                            company
                            firstName
                            lastName
                            address1
                            address2
                            address3
                            address4
                            postCode
                            city
                            state
                            country
                            email
                            phone
                            carrierId
                            method
                            needcn23
                        }
                        lineItems {
                            _id
                            name
                            qty
                            preparedQty
                            barCode
                            weight
                        }
                        locked {
                            userId
                            timestamp
                        }
                    }
                    pages
                    ordersCount
                    userId
                    documentsCount {
                        ordersNotPrepared
                        ordersPartiallyPrepared
                        parcelsToShip
                    }
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getAllOrders;
}

/**
 * Récupère le nombre de commandes selon leur statut
 */
async function getOrdersCount(status, preparedStatus) {
    const logged = await checkLoggedIn();
    var data = { getAllOrders: [] };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const page = 1;

        const query = gql`
            query {
                getAllOrders(page: ${page}, status: "${status}", preparedStatus: "${preparedStatus}")
                {
                    ordersCount
                }
            }
        `;

        data = await graphQLClient.request(query);
    }
    

    return data.getAllOrders;
}

/**
 * Récupère le nombre de commandes selon leur statut
 */
async function getParcelsCount(status) {
    const logged = await checkLoggedIn();
    var data = { getParcels: [] };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const page = 1;

        const query = gql`
            query {
                getParcels(page: ${page}, status: "${status}")
                {
                    parcelsCount
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getParcels;
}

/**
 * Récupère en une requête le nombre de commandes et de colis
 */
async function getDocumentsCount() {
    const logged = await checkLoggedIn();
    var data = { getDocumentsCount: [] };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            query {
                getDocumentsCount
                {
                    ordersNotPrepared
                    ordersPartiallyPrepared
                    parcelsToShip
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getDocumentsCount;
}

/**
 * Récupère une commande spécifique
 */
async function getOrder(id, lock = 'a') {
    const logged = await checkLoggedIn();
    var data = {
        getOrder: null
    };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            {
                getOrder(id: ${id}, lock: "${lock}") {
                    _id
                    dateCreated
                    status
                    prepared
                    customerNote
                    prepared
                    emptyParcelWeight
                    customer {
                        _id
                        firstName
                        lastName
                        email
                        shipping {
                            company
                            firstName
                            lastName
                            address1
                            address2
                            postCode
                            city
                            state
                            country
                            email
                            phone
                        }
                    }
                    shipping {
                        company
                        firstName
                        lastName
                        address1
                        address2
                        address3
                        address4
                        postCode
                        city
                        state
                        country
                        email
                        phone
                        carrierId
                        method
                        pickupLocationId
                        needcn23
                    }
                    lineItems {
                        _id
                        name
                        qty
                        preparedQty
                        barCode
                        weight
                    }
                    locked {
                        userId
                        timestamp
                    }
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getOrder;
}

/**
 * Renvoi les informations sur un utilisateur à partir de son Id
 * 
 * @param {String} id
 */
async function getUser(id) {
    if (!id) { id = "0" };
    const logged = await checkLoggedIn();
    var data = { getuser: null };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_USER, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            query {
                getUser(id: "${id}") {
                    firstName
                    lastName
                    currentCompany
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getUser;
}

/**
 * Vérifie si une commande est verrouillée par un autre utilisateur
 */
async function orderLocked(orderId) {
    const logged = await checkLoggedIn();
    var data = {
        orderLocked: null
    };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            {
                orderLocked(id: ${orderId})
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.orderLocked;
}

/**
 * Modifie une adresse de livraison
 */
async function updateShippingAddress(orderId, shippingAddress) {
    const logged = await checkLoggedIn();
    var data = {
        updateShippingAddress: null
    };

    if (logged.logged && logged.token && orderId && shippingAddress) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            mutation {
                updateShippingAddress(
                    orderId: ${orderId},
                    shippingAddress: {
                        ${shippingAddress.firstName ? 'firstName: ' + shippingAddress.firstName + ',' : ''}
                        ${shippingAddress.lastName ? 'lastName: ' + shippingAddress.lastName + ',' : ''}
                        ${shippingAddress.address1 ? 'address1: ' + shippingAddress.address1 + ',' : ''}
                        ${shippingAddress.address2 ? 'address2: ' + shippingAddress.address2 + ',' : ''}
                        ${shippingAddress.address3 ? 'address3: ' + shippingAddress.address3 + ',' : ''}
                        ${shippingAddress.address4 ? 'address4: ' + shippingAddress.address4 + ',' : ''}
                        ${shippingAddress.postCode ? 'postCode: ' + shippingAddress.postCode + ',' : ''}
                        ${shippingAddress.city ? 'city: ' + shippingAddress.city + ',' : ''}
                        ${shippingAddress.country ? 'country: ' + shippingAddress.country + ',' : ''}
                        ${shippingAddress.phone ? 'phone: ' + shippingAddress.phone + ',' : ''}
                    }
                ) {
                    _id
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.updateShippingAddress;
}

/**
 * Crée un colis
 */
async function createParcel(orderId, lineItems, shippingAmount) {
    const logged = await checkLoggedIn();

    var data = {
        createParcel: null
    }

    // Parse les produits préparés pour la requête
    var items = '';
    Object.keys(lineItems).map(function(key) {
        items += `{ _id: ${key}, preparedQty: ${lineItems[key]} }, `;
    });
    
    if (logged.logged && logged.token ) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        var newShippingAmount = parseFloat(shippingAmount);

        const query = gql`
            mutation {
                createParcel(
                    orderId: ${orderId}
                    lineItems: [${items}]
                    shippingAmount: ${newShippingAmount}
                    returnExplanations: ""
                    cn23Comments: ""
                ) {
                    _id
                    parcelNumberPartner
                    trackingCode
                    labelFileName
                    cn23FileName
                }
            }
        `;

        data = await graphQLClient.request(query);
    }
    
    return data.createParcel;
}

/**
 * Récupère une liste des colis
 */
async function getParcels(page, status) {
    const logged = await checkLoggedIn();

    var data = { getParcels: null };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            query {
                getParcels(page: ${page}, status: "${status}") {
                    parcels {
                        _id
                        trackingCode
                        orderId
                        dateCreated
                        shipping {
                            firstName
                            lastName
                            company
                            address1
                            address2
                            address3
                            address4
                            city
                            state
                            postCode
                            country
                            email
                            phone
                        }
                        lineItems {
                            _id
                            name
                            qty
                        }
                        labelFileName
                        cn23FileName
                        userId
                    }
                    pages
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getParcels;
}

/**
 * Récupère une liste des colis par un numéro de commande
 */
async function getParcelsById(orderId) {
    const logged = await checkLoggedIn();

    var data = { getParcelsById: null };

    if (logged.logged && logged.token) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            query {
                getParcelsById(orderId: ${orderId}) {
                    parcels {
                        _id
                        trackingCode
                        orderId
                        dateCreated
                        shipping {
                            firstName
                            lastName
                            company
                            address1
                            address2
                            address3
                            address4
                            city
                            state
                            postCode
                            country
                            email
                            phone
                        }
                        lineItems {
                            _id
                            name
                            qty
                        }
                        labelFileName
                        cn23FileName
                        userId
                    }
                    pages
                }
            }
        `;

        data = await graphQLClient.request(query);
    }

    return data.getParcelsById;
}

/**
 * Lance la génération du nouveau bordereau dans l'API
 */
async function createBordereau(carrierId) {
    const logged = await checkLoggedIn();
    var data = { createBordereau: null };

    if (logged.logged !== undefined && logged.token !== undefined && carrierId !== undefined) {
        const graphQLClient = new GraphQLClient(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_ENGINE, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': logged.token
            }
        });

        const query = gql`
            mutation {
                createBordereau(carrierId: "${carrierId}") {
                    _id
                    fileName
                }
            }
        `;
        
        data = await graphQLClient.request(query);
    }

    return data.createBordereau;
}

export default checkLoggedIn;
export { checkLoggedIn, createBordereau, getDocumentsCount, getOrders, getOrder, getOrdersCount, getParcelsCount, orderLocked, updateShippingAddress, createParcel, getParcels, getParcelsById, getUser };