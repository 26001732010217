import React from 'react';
import Modal from 'react-modal';
import { getOrders } from '../services/functions.js';
import OrderLine from './OrderLine';
import Pagination from './Pagination';

Modal.setAppElement('#root');

class OrdersList extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.translation;
        this.timer = null;
        this.handlePreparedQty = this.handlePreparedQty.bind(this);
        this.enablePartialValidation = this.enablePartialValidation.bind(this);
        this.getOrdersPage = this.getOrdersPage.bind(this);
        this.orderLineCallBack = this.orderLineCallBack.bind(this);
        this.searchOrder = this.searchOrder.bind(this);
        this.setPageCallBack = this.setPageCallBack.bind(this);
        this.state = {
            modalIsOpenView: false,
            modalIsOpenShipping: false,
            modalIsOpenPrepare: false,
            validateDisabled: true,
            checkPartial: false,
            checkPartialDisabled: true,
            preparedQty: 0,
            orders: [],
            orderIdToSearch: '',
            ordersList: [],
            pages: 0,
            page: 1,
            refreshPaused: false,
            results: 'no'
        }
    }

    componentDidMount() {
        const that = this;
        this.getOrdersPage();
        this.timer = setInterval(function() {
            if (!that.state.refreshPaused) { that.getOrdersPage(); }
        }, 2000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // clearInterval(this.timer);
            this.setState({ordersList: []});
            this.setState({results: 'loading'});
            this.setState({orderIdToSearch: ''});
            // const that = this;
            // this.timer = setInterval(function() {
            //     if (!that.state.refreshPaused) {
            //         that.getOrdersPage();
            //     }
            // }, 2000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getOrdersPage() {
        const that = this;
        const status = 'processing';

        var isEven = false;
        var ordersList = [];
        
        // this.setState({ ordersList: [] });
        this.setState({results: 'loading'});

        getOrders(this.state.page, status, this.props.preparedStatus, this.state.orderIdToSearch).then(function(res) {
            if (res !== undefined && res !== null) {

                if (res.pages !== undefined) {
                    that.setState({ pages: res.pages });
                }

                if (res.orders !== undefined) {
                    that.setState({ orders: res.orders });
                }
    
                for (var i = 0; i < res.orders.length; i++) {
                    ordersList.push(
                        <OrderLine key={res.orders[i]._id} order={res.orders[i]} userId={res.userId} isEven={isEven} translation={that.t} parentCallBack={that.orderLineCallBack} />
                    );
    
                    if (isEven) {
                        isEven = false;
                    } else {
                        isEven = true;
                    }
                }

                that.setState({results: 'yes'});
                that.props.parentCallback({
                    ordersNotPrepared: res.documentsCount.ordersNotPrepared,
                    ordersPartiallyPrepared: res.documentsCount.ordersPartiallyPrepared,
                    parcelsToShip: res.documentsCount.parcelsToShip
                });
            } else {
                that.setState({results: 'no'});
            }
            that.setState({ordersList: ordersList});
        });
    }

    /**
     * Récupère les valeurs de retour de chaque composant pour calculer si la quantité totale est validée
     * pour rendre le bouton de validation disponible
     * 
     * @param {Object} e
     */
    handlePreparedQty(e) {
        const newQty = this.state.preparedQty + e.preparedQty;

        this.setState(state => ({
            preparedQty: newQty
        }));

        if (newQty === this.totalQty) {
            this.setState(state => ({
                validateDisabled: false,
                checkPartialDisabled: true,
            }));
        } else {
            if (newQty === 0) {
                this.setState(state => ({
                    checkPartialDisabled: true,
                }));
            } else {
                this.setState(state => ({
                    checkPartialDisabled: false,
                }));
            }

            this.setState(state => ({
                validateDisabled: true,
            }));
        }
        
        this.setState(state => ({
            checkPartial: false,
        }));
    };

    /**
     * Permet de rendre disponible le bouton de validation pour l'envoi partiel de colis
     * 
     * @param {Object} e
     */
    enablePartialValidation(e) {
        if (e.target.checked) {
            this.setState(state => ({
                validateDisabled: false,
                checkPartial: true
            }));
        } else {
            this.setState(state => ({
                validateDisabled: true,
                checkPartial: false
            }));
        }
    }

    setPageCallBack(pageNumber) {
        this.state.page = pageNumber;
        this.getOrdersPage();
    }

    /**
     * Retour d'un composant de ligne de commande
     * 
     * @param {Object} args
     */
    orderLineCallBack(args) {
        this.setState({refreshPaused: args.refreshPaused});
    }

    /**
     * Filtre par numéro de colis
     */
    searchOrder(e) {
        this.setState({ordersList: []});
        this.setState({results: 'loading'});
        this.setState({orderIdToSearch: e.target.value});
        // this.getOrdersPage();
    }

    render() {
        return (
            <div>
                <h1>{this.props.pagetitle}</h1>
                <div><input className="searchInput" type="text" name="search" placeholder={this.t('common.searchOrderPlaceHolder')} onChange={this.searchOrder} value={this.state.orderIdToSearch} /></div>
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>{this.t('common.status')}</th>
                            <th>{this.t('common.order')}</th>
                            <th>{this.t('common.date')}</th>
                            <th>{this.t('common.customer')}</th>
                            <th>{this.t('common.address')}</th>
                            <th>{this.t('common.actions')}</th>
                        </tr>
                    </thead>

                    <tbody>{this.state.ordersList.length ? this.state.ordersList
                    : ! this.state.ordersList.length && this.state.results === 'loading' ? <tr><td className="messageResults" colSpan="6">{this.t('common.loading')}</td></tr>
                    : ! this.state.ordersList.length && this.state.results === 'no' ? <tr><td className="messageResults" colSpan="6">{this.t('common.noResults')}</td></tr> : <tr></tr>}</tbody>
                </table>
                <Pagination pages={this.state.pages} parentCallBack = {this.setPageCallBack} translation = {this.t} />
            </div>
       );
    }
}

export default OrdersList;