import React from 'react';
import { NavLink } from 'react-router-dom';
import { LoginIcon  } from '@heroicons/react/solid'
import { withTranslation } from 'react-i18next';

class NavLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { logged: props.logged };
    }

    componentWillUpdate(prevState) {
        if (prevState !== this.state) {
            if (prevState.logged !== this.state.logged) {
                this.setState({ logged: this.props.logged });
            }
        }
    }

    render() {
        return (
            <NavLink className={(navData) => (navData.isActive ? "navlink nav-active" : 'navlink')} to="/login">
                <LoginIcon className="navicon" />
                {this.state.logged ? this.props.t('login.logout') : this.props.t('login.login')}
            </NavLink>
        );
    }
}

export default withTranslation()(NavLogin);