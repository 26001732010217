import React from 'react';
import { DocumentTextIcon } from '@heroicons/react/solid';
import checkLoggedIn, { getParcels, createBordereau } from '../services/functions';
import ParcelLine from './ParcelLine';
import Pagination from './Pagination';
import axios from 'axios';

class ParcelsToShip extends React.Component {
    constructor(props) {
        super(props);

        this.t = props.translation;
        this.state = {
            bordereau: {
                fileName: ''
            },
            currentPage: 1,
            modalIsOpenCN23: false,
            modalIsOpenLabel: false,
            parcelsList: [],
            pages: 0,
            btnGenerate: {
                colissimo: {
                    disabled: '',       // vide, 'disabled'
                    statusClass: '',    // vide, 'pending', 'success', 'error'
                    text: this.t('parcels.generateParcelsList'),
                    fileName: ''
                },
                mondial_relay: {
                    disabled: '',
                    statusClass: '',
                    text: this.t('parcels.generateParcelsList'),
                    fileName: ''
                }
            }
        }

        this.getParcelsPage = this.getParcelsPage.bind(this);
        this.setPageCallBack = this.setPageCallBack.bind(this);
        this.downloadParcelsList = this.downloadParcelsList.bind(this);
    }

    componentDidMount() {
        this.getParcelsPage();
    }

    /**
     * Récupère une liste des colis
     */
    getParcelsPage() {
        const that = this;
        const status = 'TOSHIP';

        var isEven = false;
        var parcelsList = [];

        getParcels(that.state.currentPage, status).then(function(res) {
            that.setState({ pages: res.pages });

            res.parcels.forEach(item => {
                parcelsList.push(
                    <ParcelLine key={item._id} trackingCode={item.trackingCode} orderId={item.orderId} dateCreated={item.dateCreated} address={item.shipping.firstName + ' ' + item.shipping.lastName + ' / ' + item.shipping.company + ' / ' + item.shipping.address1 + ' ' + item.shipping.address2 + ' ' + item.shipping.postCode + ' ' + item.shipping.city + ' (' + item.shipping.country + ')'} labelFileName={item.labelFileName} cn23FileName={item.cn23FileName} lineItems={item.lineItems} userId={item.userId} translation={that.t} isEven={isEven} />
                );

                if (isEven) {
                    isEven = false;
                } else {
                    isEven = true;
                }
            })

            that.setState({parcelsList: parcelsList});
        });
    }

    setPageCallBack(pageNumber) {
        this.state.currentPage = pageNumber;
        this.getParcelsPage();
    }

    /**
     * Génère le bordereau de dépôt des colis
     */
    generateParcelsList(carrierId) {
        if (this.state.btnGenerate[carrierId].fileName) {
            this.downloadParcelsList(carrierId);
            return;
        }

        if (carrierId !== undefined) {
            const that = this;
            this.setState(prevState => ({
                btnGenerate: {
                    ...prevState.btnGenerate,
                    [carrierId]: {
                        ...prevState.btnGenerate[carrierId],
                        disabled: 'disabled',
                        statusClass: 'pending',
                        text: that.t('parcels.generateParcelsPending')
                    }
                }
            }));

            createBordereau(carrierId).then(function(res) {
                if (res) {
                    that.setState(prevState => ({
                        btnGenerate: {
                            ...prevState.btnGenerate,
                            [carrierId]: {
                                ...prevState.btnGenerate[carrierId],
                                disabled: '',
                                statusClass: 'success',
                                text: that.t('parcels.generateParcelsSuccess'),
                                fileName: res.fileName
                            }
                        }
                    }));

                    that.getParcelsPage();
                } else {
                    that.setState(prevState => ({
                        btnGenerate: {
                            ...prevState.btnGenerate,
                            [carrierId]: {
                                ...prevState.btnGenerate[carrierId],
                                statusClass: 'error',
                                text: that.t('parcels.generateParcelsError')
                            }
                        }
                    }));
                }
            });
        }
    }

    /**
     * Fonction TEST
     */
    newGenerateParcelsList(carrierId) {
        this.setState(prevState => ({
            btnGenerate: {
                ...prevState.btnGenerate,
                [carrierId]: {
                    ...prevState.btnGenerate[carrierId],
                    statusClass: 'error'
                }
            }
        }));
    }

    /**
     * Télécharge le bordereau qui vient d'être généré
     */
    async downloadParcelsList(carrierId) {
        const logged = await checkLoggedIn();

        if (logged.logged && logged.token) {
            await axios.get(process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + process.env.REACT_APP_API_ENDPOINT_DOWNLOAD, {
                headers: {
                    'Accept': 'application/pdf;application/json',
                    'x-access-token': logged.token
                },
                responseType: 'blob',
                params: { fileName: this.state.btnGenerate[carrierId].fileName }
            })
            .then(function(res) {
                const blobObj = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blobObj);
                window.open(url, '_blank');
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render() {
        return (
            <div>
                <h1>{this.t('orders.toShip')}</h1>

                <div className="mainButtonsBlock">
                    <button className={this.state.btnGenerate.colissimo.statusClass} onClick={() => this.generateParcelsList('colissimo')} disabled={this.state.btnGenerate.colissimo.disabled}><DocumentTextIcon className="icon" />{this.state.btnGenerate.colissimo.text} {this.t('carrier.colissimo')}</button>
                    <button className={this.state.btnGenerate.mondial_relay.statusClass} onClick={() => this.generateParcelsList('mondial_relay')} disabled={this.state.btnGenerate.mondial_relay.disabled}><DocumentTextIcon className="icon" />{this.state.btnGenerate.mondial_relay.text} {this.t('carrier.mondial_relay')}</button>
                </div>

                <table className="customTable">
                    <thead>
                        <tr>
                            <th>{this.t('common.trackingCode')}</th>
                            <th>{this.t('common.order')}</th>
                            <th>{this.t('common.date')}</th>
                            <th>{this.t('common.address')}</th>
                            <th>{this.t('common.preparedByUser')}</th>
                            <th>{this.t('common.actions')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.parcelsList}
                    </tbody>
                </table>
                
                <Pagination pages={this.state.pages} parentCallBack = {this.setPageCallBack} translation = {this.t} />
            </div>
        );
    }
}

export default ParcelsToShip;